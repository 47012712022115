.complains-page_wrapper {

    .complains-page_header {
        display: flex;
        width: 100%;
        align-items: baseline;
        margin-bottom: 40px;

        h5, a {
            margin-right: 45px;
        }

        span {
            font-weight: 300;
            margin-right: 45px;

        }

        button {
            font-size: 12px;
        }
    }
    .complains-link {
        text-decoration: underline;
    }
    
    .complain-action {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
    }

    .pagination-container {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
