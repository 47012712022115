@font-face {
  font-family: "Roboto", sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}